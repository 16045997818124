import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { GET_PRIVATE_KEY_VALUE } from '../graphql/Queries';
import { DELETE_ENV, DELETE_ENV_KEYS, VERIFY_ENV } from '../graphql/Mutations';

export default function TwoFAModal({
  visible,
  setVisible,
  setValueModal,
  privateKeyValueform,
  refetchEnvKeyValueData,
  selectedRepositories,
  setActionKey,
  actionKey,
  setActionId,
  actionId,
}) {
  const [form] = Form.useForm();
  const [twoFACode, setTwoFACode] = useState(null);
  const [isWrongCode, setIsWrongCode] = useState(false);

  const [veiwValue] = useLazyQuery(GET_PRIVATE_KEY_VALUE, {
    onCompleted(res) {
      privateKeyValueform.setFieldsValue({
        envKeyValue: res?.getPrivateKeyValue?.value,
      });
      form.resetFields();
      setIsWrongCode(false);
      setVisible(false);
      setActionKey(null);
      setValueModal(!!actionId);
    },
    onError() {
      form.resetFields();
      setIsWrongCode(true);
    },
  });

  const [deleteEnvKey] = useMutation(DELETE_ENV_KEYS, {
    onCompleted() {
      refetchEnvKeyValueData({
        variables: {
          data: {
            refId: selectedRepositories,
          },
        },
      });
      setIsWrongCode(false);
      setVisible(false);
      setActionKey(null);
      setActionId(null);
      form.resetFields();
    },
    onError() {
      setIsWrongCode(true);
      form.resetFields();
    },
  });

  const [deleteEnv] = useMutation(DELETE_ENV, {
    onCompleted() {
      refetchEnvKeyValueData({
        variables: {
          data: {
            refId: selectedRepositories,
          },
        },
      });
      setIsWrongCode(false);
      setVisible(false);
      setActionKey(null);
      setActionId(null);
      form.resetFields();
    },
    onError() {
      setIsWrongCode(true);
      form.resetFields();
    },
  });

  const [verifyEnv] = useMutation(VERIFY_ENV, {
    onCompleted() {
      refetchEnvKeyValueData({
        variables: {
          data: {
            refId: selectedRepositories,
          },
        },
      });
      setIsWrongCode(false);
      setVisible(false);
      setActionKey(null);
      setActionId(null);
      form.resetFields();
    },
    onError() {
      setIsWrongCode(true);
      form.resetFields();
    },
  });

  const submit = () => {
    if (twoFACode?.length < 6) return;
    if (actionKey === 'VIEW_PRIVATE_KEY') {
      veiwValue({
        variables: {
          data: {
            twoFaCode: twoFACode,
            id: actionId,
          },
        },
      });
    }
    if (actionKey === 'DELETE_KEY') {
      deleteEnvKey({
        variables: {
          data: {
            twoFACode,
            keyId: actionId,
          },
        },
      });
    }
    if (actionKey === 'DELETE_ENV') {
      deleteEnv({
        variables: {
          data: {
            twoFACode,
            id: actionId,
          },
        },
      });
    }
    if (actionKey === 'VERIFY_ENV') {
      verifyEnv({
        variables: {
          data: {
            twoFACode,
            environmentId: actionId,
          },
        },
      });
    }
  };

  return (
    <Modal
      open={visible}
      centered
      title="Enter Authentication Code"
      onOk={() => submit()}
      onCancel={() => {
        setActionKey(null);
        setActionId(null);
        setVisible(false);
        setIsWrongCode(false);
      }}
      okText="Verify"
    >
      <Form form={form} layout="vertical" className="confirmation-form">
        <Form.Item name="confirmationCode">
          <div className="mt-16 d-flex justify-center">
            <Input.OTP
              onChange={(value) => {
                setTwoFACode(value);
                form.setFieldValue('confirmationCode', value);
              }}
              status={isWrongCode && 'error'}
            />
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}
