import { LockOutlined, SafetyCertificateFilled } from '@ant-design/icons';
import { Checkbox, Form } from 'antd';

import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import ProjectBranchSelection from './components/ProjectBranchSelection';
import ProjectEnvViewTable from './components/ProjectEnvViewTable';
import AddKeyModal from './modals/AddKeyModal';
import TableActions from './components/TableActions';
import DuplicateKeyValueModal from './modals/DuplicateKeyValueModal';
import DeleteEnvModal from './modals/DeleteEnvModal';
import ViewKeyValueModal from './modals/ViewKeyValueModal';
import { GET_KEY_VALUE_LIST } from './graphql/Queries';
import TwoFAModal from './modals/TwoFAModal';
import DeleteKeyModal from './modals/DeleteKeyModal';
import './dashboard.less';
import { UPDATE_ENV_KEY_VALUE } from './graphql/Mutations';

const defaultColumns = [
  {
    title: 'Sr.No.',
    dataIndex: 'key',
    width: 40,
    fixed: 'left',
    render(_, __, index) {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      return (index += 1);
    },
  },
  {
    title: 'Key',
    dataIndex: 'environmentKey',
    key: 'environmentKey',
    width: 360,
    fixed: 'left',
    render: (value, record) => (
      <div className="align-center gap-24">
        {value} {record?.isPrivate && <LockOutlined />}
      </div>
    ),
  },
];

function Dashboard() {
  const [privateKeyValueform] = Form.useForm();

  const [newKeyModalOpen, setNewKeyModalOpen] = useState(false);
  const [deleteEnvModalOpen, setDeleteEnvModalOpen] = useState(false);
  const [veiwKeyValueModalOpen, setVeiwKeyValueModalOpen] = useState(false);
  const [twoFAModalOpen, setTwoFAModalOpen] = useState(false);
  const [duplicateKeyModalOpen, setDuplicateKeyModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRepositories, setSelectedRepositories] = useState(null);
  const [repoId, setRepoId] = useState(null);
  const [deleteKeyModalOpen, setDeleteKeyModalOpen] = useState(false);
  const [repoEnvs, setRepoEnvs] = useState([]);

  const [actionId, setActionId] = useState('');
  const [actionKey, setActionKey] = useState(null);
  const [data, setData] = useState();
  const [envColumns, setEnvColumns] = useState([...defaultColumns]);

  const [
    fetchKeyValues,
    { data: envData, refetch: refetchEnvKeyValueData, loading },
  ] = useLazyQuery(GET_KEY_VALUE_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setRepoId(res?.getKeyValueList?.repositoryId);
      const structuredColumn = res?.getKeyValueList?.environments?.map(
        (env) => ({
          title: (
            <div className="d-flex justify-between">
              <div className="d-flex gap-8 verified-env">
                {env?.name}
                {env?.isVerified && <SafetyCertificateFilled />}
              </div>
              <Checkbox onChange={() => setActionId(env?.id)} />
            </div>
          ),
          dataIndex: env?.name,
          key: env?.name,
          editable: true,
          render: (value, record) => {
            const columnToSetValue = record?.environmentKeyValues?.find(
              (envName) => env?.name === envName?.environmentName,
            );
            return (
              <div className="d-flex align-center justify-between">
                <div
                  title={columnToSetValue?.environmentKeyValue}
                  className="editable-cell"
                >
                  {columnToSetValue?.environmentKeyValue || '-'}
                </div>
              </div>
            );
          },
        }),
      );
      setRepoEnvs(res?.getKeyValueList?.environments);
      setEnvColumns([...defaultColumns, ...structuredColumn]);
      setData([...res?.getKeyValueList?.keys]);
    },
    onError: () => {},
  });

  const [updateEnvKeyValue] = useMutation(UPDATE_ENV_KEY_VALUE, {
    onCompleted() {
      fetchKeyValues({
        variables: {
          data: {
            refId: selectedRepositories,
          },
        },
      });
    },
    onError() {},
  });

  return (
    <>
      <div className="d-flex gap-16 flex-vertical justify-between">
        <div className="d-flex gap-16">
          <ProjectBranchSelection
            selectedRepositories={selectedRepositories}
            setSelectedRepositories={setSelectedRepositories}
            refetchEnvKeyValueData={refetchEnvKeyValueData}
            repoEnvs={repoEnvs}
          />
        </div>
        <div>
          <ProjectEnvViewTable
            data={data}
            selectedRowKeys={selectedRowKeys}
            selectedRepositories={selectedRepositories}
            fetchKeyValues={fetchKeyValues}
            envColumns={envColumns}
            setSelectedRowKeys={setSelectedRowKeys}
            setTwoFAModalOpen={setTwoFAModalOpen}
            setDeleteKeyModalOpen={setDeleteKeyModalOpen}
            setActionKey={setActionKey}
            setActionId={setActionId}
            updateEnvKeyValue={updateEnvKeyValue}
            loading={loading}
          />
        </div>
        <div>
          <TableActions
            setNewKeyModalOpen={setNewKeyModalOpen}
            setActionKey={setActionKey}
            setTwoFAModalOpen={setTwoFAModalOpen}
            actionId={actionId}
            setDuplicateKeyModalOpen={setDuplicateKeyModalOpen}
            setDeleteEnvModalOpen={setDeleteEnvModalOpen}
            selectedKeys={selectedRowKeys}
            repoId={repoId}
          />
        </div>
      </div>
      <DeleteKeyModal
        visible={deleteKeyModalOpen}
        setVisible={setDeleteKeyModalOpen}
        setActionKey={setActionKey}
        setTwoFaModalVisible={setTwoFAModalOpen}
      />
      <AddKeyModal
        visible={newKeyModalOpen}
        setVisible={setNewKeyModalOpen}
        repoId={repoId}
        selectedRepositories={selectedRepositories}
        refetchEnvKeyValueData={refetchEnvKeyValueData}
      />
      <TwoFAModal
        visible={twoFAModalOpen}
        setVisible={setTwoFAModalOpen}
        setValueModal={setVeiwKeyValueModalOpen}
        privateKeyValueform={privateKeyValueform}
        refetchEnvKeyValueData={refetchEnvKeyValueData}
        selectedRepositories={selectedRepositories}
        actionId={actionId}
        actionKey={actionKey}
        setActionKey={setActionKey}
        setActionId={setActionId}
      />
      <ViewKeyValueModal
        visible={veiwKeyValueModalOpen}
        setVisible={setVeiwKeyValueModalOpen}
        privateKeyValueform={privateKeyValueform}
        updateEnvKeyValue={updateEnvKeyValue}
        actionId={actionId}
      />
      <DuplicateKeyValueModal
        visible={duplicateKeyModalOpen}
        setVisible={setDuplicateKeyModalOpen}
        selectedKeys={selectedRowKeys}
        envRecords={envData?.getKeyValueList?.environments}
        refetchEnvKeyValueData={refetchEnvKeyValueData}
        selectedRepositories={selectedRepositories}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <DeleteEnvModal
        visible={deleteEnvModalOpen}
        setVisible={setDeleteEnvModalOpen}
        setTwoFAModalOpen={setTwoFAModalOpen}
        setActionKey={setActionKey}
      />
    </>
  );
}

export default Dashboard;
