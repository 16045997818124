import { gql } from '@apollo/client';

export const ADD_ENV = gql`
  mutation addEnvironment($data: AddEnvironmentInput!) {
    addEnvironment(data: $data) {
      message
    }
  }
`;

export const DELETE_ENV = gql`
  mutation deleteEnvironment($data: DeleteEnvironmentInput) {
    deleteEnvironment(data: $data) {
      message
    }
  }
`;

export const VERIFY_ENV = gql`
  mutation updateEnvironment($data: UpdateEnvironmentType) {
    updateEnvironment(data: $data) {
      message
    }
  }
`;

export const UPDATE_ENV_KEY_VALUE = gql`
  mutation updateEnvironmentKeyValue($data: UpdateEnvironmentKeyValueInput) {
    updateEnvironmentKeyValue(data: $data) {
      message
    }
  }
`;

export const ADD_ENV_KEYS = gql`
  mutation addKey($data: AddKeyInput) {
    addKey(data: $data) {
      message
    }
  }
`;

export const DELETE_ENV_KEYS = gql`
  mutation deleteKey($data: DeleteKeyInput) {
    deleteKey(data: $data) {
      message
    }
  }
`;

export const DUPLICATE_ENV_KEY_VALUE = gql`
  mutation DuplicateEnvironmentKeyValue(
    $data: DuplicateEnvironmentKeyValueInput
  ) {
    duplicateEnvironmentKeyValue(data: $data) {
      message
    }
  }
`;
