import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { BREAKPOINTS, ROUTES } from '../common/constants';
import MobileLogoComponent from '../components/MobileLogoComponent';
import './App.css';
import AppHeader from './components/header/AppHeader';
import UserProfile from './components/header/UserProfile';

const { Content, Footer } = Layout;
const App = () => {
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [isActive, setActive] = useState(false);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Layout hasSider>
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Layout className="site-layout">
        <AppHeader>
          <div className="header-wrapper">
            <Link id="logo" to={ROUTES?.MAIN}>
              <MobileLogoComponent className="mr-8" />
              LW Vault
            </Link>
            <div className="d-flex align-center gap-16">
              <UserProfile />
            </div>
          </div>
        </AppHeader>
        <Content className="wrapper">
          <Outlet />
        </Content>
        <Footer>
          <div className="text-center">
            Logicwind © {new Date().getFullYear()}
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
