import { useMutation } from '@apollo/client';
import { Card, Spin } from 'antd';
import React, { useContext } from 'react';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { GOOGLE_LOGIN } from './graphql/Mutations';
import MobileLogoComponent from '../../components/MobileLogoComponent';
import { signIn } from '../../firebase';
import './auth.less';
import useRouter from '../../hooks/useRouter';

const Login = () => {
  const { initializeAuth } = useContext(AppContext);
  const { navigate } = useRouter();

  const [
    googleLoginMutate,
    { data: googleData, loading: loginLoading },
  ] = useMutation(GOOGLE_LOGIN, {
    onError() {},
  });

  function successCallback(
    accessToken,
    userData,
    refreshToken,
    isTwoFaEnabled,
    twoFaObj,
  ) {
    initializeAuth(
      accessToken,
      userData,
      refreshToken,
      isTwoFaEnabled,
      twoFaObj,
    );
    if (!isTwoFaEnabled) {
      navigate(ROUTES?.VERIFY, { replace: true, state: twoFaObj });
    } else {
      navigate(ROUTES?.MAIN);
    }
  }

  const googleLogin = async (res) => {
    try {
      await googleLoginMutate({
        variables: {
          data: {
            token: `${res?.[0]}`,
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  if (googleData) {
    const {
      accessToken,
      user,
      refreshToken,
      twoFaObj,
    } = googleData?.googleLogin?.data;
    const { isTwoFaEnabled } = googleData?.googleLogin?.data?.user;

    if (successCallback) {
      successCallback(
        accessToken,
        user,
        refreshToken,
        isTwoFaEnabled,
        twoFaObj,
      );
    }
  }

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <div id="logo" className="header-wrapper text-center">
              <MobileLogoComponent className="mr-8" />
              <div className="mt-8">
                <h3>LW Vault</h3>
              </div>
            </div>
            <div className="mt-16">
              <GoogleLoginButton
                onClick={async () => {
                  const res = await signIn();
                  googleLogin(res);
                }}
              />
            </div>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Logicwind © {new Date()?.getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default Login;
