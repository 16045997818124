import { LoginOutlined } from '@ant-design/icons';
import { Avatar, Button, Popover } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';

const UserProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const { firstName = '', lastName = '', email = '', profile = '' } =
    getCurrentUser() || {};
  const { navigate } = useRouter();

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      content={
        <>
          <div className="d-flex justify-center align-center gap-8 flex-vertical">
            {!profile ? (
              <Avatar
                size="large"
                alt="Avatar"
              >{`${firstName?.[0]?.toLocaleUpperCase()}${lastName?.[0]?.toLocaleUpperCase()}`}</Avatar>
            ) : (
              <Avatar size="large" alt="Avatar" src={profile} />
            )}
            <h4 className="m-0">Hi, {firstName}!</h4>
            <span>{email}</span>
            <Button
              danger
              onClick={() => navigate(ROUTES?.LOGOUT, { replace: true })}
            >
              <LoginOutlined /> Logout
            </Button>
          </div>
        </>
      }
    >
      {!profile ? (
        <Avatar alt="Avatar">{`${firstName?.[0]?.toLocaleUpperCase()}${lastName?.[0]?.toLocaleUpperCase()}`}</Avatar>
      ) : (
        <Avatar alt="Avatar" src={profile} />
      )}
    </Popover>
  );
};

export default UserProfile;
