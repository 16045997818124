import {
  BlockOutlined,
  DeleteOutlined,
  ExportOutlined,
  KeyOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

export default function TableActions({
  setNewKeyModalOpen,
  setActionKey,
  setDuplicateKeyModalOpen,
  setDeleteEnvModalOpen,
  selectedKeys,
  setTwoFAModalOpen,
  actionId,
  repoId,
}) {
  return (
    <>
      <div className="d-flex gap-16">
        <Button type="primary" disabled className="d-flex align-center">
          <ExportOutlined /> Export
        </Button>
        <Button
          disabled={actionId?.length === 0}
          onClick={() => {
            setActionKey('VERIFY_ENV');
            setTwoFAModalOpen(true);
          }}
          type="primary"
          className="d-flex align-center"
        >
          <SafetyCertificateOutlined /> Verify
        </Button>
        <Button
          onClick={() => setNewKeyModalOpen(true)}
          disabled={!repoId}
          type="primary"
          className="d-flex align-center"
        >
          <KeyOutlined /> Add Key
        </Button>
        <Button
          onClick={() => setDuplicateKeyModalOpen(true)}
          disabled={selectedKeys?.length <= 0}
          type="primary"
          className="d-flex align-center"
        >
          <BlockOutlined /> Duplicate Key/s
        </Button>
        <Button
          onClick={() => setDeleteEnvModalOpen(true)}
          disabled={!actionId}
          type="primary"
          className="d-flex align-center"
          danger
        >
          <DeleteOutlined /> Delete Env.
        </Button>
      </div>
    </>
  );
}
