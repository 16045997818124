import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query gitProjects($data: GitProjectInput) {
    gitProjects(data: $data) {
      count
      data {
        name
        integrations {
          referenceId
          referenceName
        }
        id
      }
    }
  }
`;

export const GET_REPOSITORIES = gql`
  query gitRepositories($where: GitProjectUniqueInput!) {
    gitRepositories(where: $where) {
      data {
        name
        id
      }
    }
  }
`;

export const GET_BRANCHS = gql`
  query gitEnvironments($where: GitRepositoryUniqueInput!) {
    gitEnvironments(where: $where) {
      data {
        name
      }
    }
  }
`;

export const GET_KEY_VALUE_LIST = gql`
  query getKeyValueList($data: GetKeyValueListInput) {
    getKeyValueList(data: $data) {
      repositoryId
      environments {
        name
        id
        isVerified
      }
      keys {
        environmentKeyId
        environmentKey
        isPrivate
        environmentKeyValues {
          environmentkeyValueId
          environmentName
          environmentId
          environmentKeyValue
        }
      }
    }
  }
`;

export const GET_PRIVATE_KEY_VALUE = gql`
  query getPrivateKeyValue($data: GetPrivateKeyValueInput) {
    getPrivateKeyValue(data: $data) {
      value
    }
  }
`;
