import { Form, Modal, Select } from 'antd';
import React from 'react';
import { useMutation } from '@apollo/client';
import { DUPLICATE_ENV_KEY_VALUE } from '../graphql/Mutations';

export default function DuplicateKeyValueModal({
  visible,
  setVisible,
  envRecords = [],
  selectedKeys = [],
  refetchEnvKeyValueData,
  selectedRepositories,
  setSelectedRowKeys,
}) {
  const [form] = Form.useForm();

  const [duplicateValue] = useMutation(DUPLICATE_ENV_KEY_VALUE, {
    onCompleted() {
      refetchEnvKeyValueData({
        variables: {
          data: {
            refId: selectedRepositories,
          },
        },
      });
      setSelectedRowKeys([])
      setVisible(false);
      form?.resetFields();
    },
    onError() {},
  });

  const onFinish = (values) => {
    const { sourceId, targetIds } = values;
    duplicateValue({
      variables: {
        data: {
          keyIds: selectedKeys,
          sourceId,
          targetIds,
        },
      },
    });
  };

  return (
    <Modal
      open={visible}
      centered
      title="Duplicate to Another ENV"
      onCancel={() => setVisible(false)}
      onOk={() => form?.submit()}
      okText="Confirm"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Source Env"
          name="sourceId"
          rules={[
            {
              required: true,
              message: 'Please enter key name',
            },
          ]}
        >
          <Select
            className="selection"
            placeholder="Select Source Env"
            options={envRecords?.map((env) => ({
              label: env?.name,
              value: env?.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Target Env"
          name="targetIds"
          rules={[
            {
              required: true,
              message: 'Please enter key name',
            },
          ]}
        >
          <Select
            className="selection"
            placeholder="Select Target Env"
            mode="multiple"
            options={envRecords?.map((env) => ({
              label: env?.name,
              value: env?.id,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
