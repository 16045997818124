import { useMutation } from '@apollo/client';
import { Checkbox, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { ADD_ENV_KEYS } from '../graphql/Mutations';

export default function AddKeyModal({
  visible,
  setVisible,
  repoId,
  refetchEnvKeyValueData,
  selectedRepositories,
}) {
  const [form] = Form.useForm();
  const [isPrivate, setIsPrivate] = useState(false);

  const [addEnvKey] = useMutation(ADD_ENV_KEYS, {
    onCompleted() {
      refetchEnvKeyValueData({
        variables: {
          data: {
            refId: selectedRepositories,
          },
        },
      });
    },
    onError() {},
  });

  const onFinish = (values) => {
    addEnvKey({
      variables: {
        data: {
          ...values,
          repositoryId: repoId,
          isPrivate,
        },
      },
    });
    form?.resetFields();
    setVisible(false);
    setIsPrivate(false);
  };

  const onChange = (e) => {
    setIsPrivate(e?.target?.checked);
  };

  return (
    <Modal
      open={visible}
      centered
      title="Create a New Key"
      onOk={() => {
        form?.submit();
      }}
      onCancel={() => setVisible(false)}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Key Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter key name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Key Value" name="value">
          <Input />
        </Form.Item>
        <Form.Item label="Key Type" name="isPrivate" valuePropName="checked">
          <Checkbox onChange={onChange} checked={isPrivate}>
            Is Private
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
