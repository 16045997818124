import { PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { differenceBy } from 'lodash';
import {
  GET_BRANCHS,
  GET_PROJECTS,
  GET_REPOSITORIES,
} from '../graphql/Queries';
import { ADD_ENV } from '../graphql/Mutations';

export default function ProjectBranchSelection({
  selectedRepositories,
  setSelectedRepositories,
  refetchEnvKeyValueData,
  repoEnvs,
}) {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [repositories, setRepositories] = useState([]);

  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const { loading: projectsLoading } = useQuery(GET_PROJECTS, {
    variables: {
      data: {
        skip: 0,
        search: '',
        limit: 10,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const fetchedProjects = [...res?.gitProjects?.data];
      setProjects(fetchedProjects);
    },
    onError: () => {},
  });

  const [fetchRepos, { loading: repositoriesLoading }] = useLazyQuery(
    GET_REPOSITORIES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const fetchedRepositories = [...res?.gitRepositories?.data];
        setRepositories(fetchedRepositories);
      },
      onError: () => {},
    },
  );

  const [fetchBranch, { loading: branchesLoading }] = useLazyQuery(
    GET_BRANCHS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const filteredBranches = differenceBy(
          res?.gitEnvironments?.data,
          repoEnvs,
          'name',
        );
        setBranchList(filteredBranches);
      },
      onError: () => {},
    },
  );

  const [addEnv] = useMutation(ADD_ENV, {
    onCompleted() {
      refetchEnvKeyValueData({
        variables: {
          data: {
            refId: selectedRepositories,
          },
        },
      });
      setSelectedBranch(null);
    },
    onError() {},
  });

  useEffect(() => {
    if (selectedProject) {
      fetchRepos({
        variables: {
          where: {
            id: selectedProject,
          },
        },
      });
    }
    if (selectedRepositories) {
      fetchBranch({
        variables: {
          where: {
            id: selectedRepositories,
          },
        },
      });
    }
  }, [selectedProject, selectedRepositories]);

  const handleAddEnv = (branchName) => {
    addEnv({
      variables: {
        data: {
          envName: branchName,
          projectRefId: selectedProject,
          repositoryRefId: selectedRepositories,
        },
      },
    });
  };

  return (
    <>
      <Select
        placeholder="Select Project"
        className="selection"
        allowClear
        options={projects?.map((project) => ({
          value: project?.integrations?.[0]?.referenceId,
          label: project?.name,
        }))}
        loading={projectsLoading}
        onSelect={(value) => {
          setSelectedProject(value);
          setSelectedRepositories(null);
          setSelectedBranch(null);
        }}
      />
      <Select
        placeholder="Select Repository"
        className="selection"
        allowClear
        disabled={!selectedProject}
        options={repositories?.map((repo) => ({
          value: repo?.id,
          label: repo?.name,
        }))}
        loading={repositoriesLoading}
        value={selectedRepositories}
        onSelect={(value) => {
          setSelectedRepositories(value);
          setSelectedBranch(null);
        }}
      />
      <Select
        className="selection"
        disabled={!selectedRepositories}
        allowClear
        options={branchList?.map((branch) => ({
          value: branch?.name,
          label: branch?.name,
        }))}
        value={selectedBranch}
        loading={branchesLoading}
        placeholder="Select branch"
        onSelect={(value) => {
          setSelectedBranch(value);
        }}
      />
      <Button
        disabled={!selectedBranch}
        onClick={() => handleAddEnv(selectedBranch)}
        type="primary"
      >
        <PlusOutlined /> Add Env.
      </Button>
    </>
  );
}
