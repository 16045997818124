import { gql } from '@apollo/client';

export const GOOGLE_LOGIN = gql`
  mutation googleLogin($data: LoginInput) {
    googleLogin(data: $data) {
      data {
        accessToken
        refreshToken
        user {
          email
          firstName
          lastName
          profile
          id
          isTwoFaEnabled
        }
        twoFaObj {
          otpauthUrl
          recoveryCode
        }
      }
      message
    }
  }
`;

export const VERIFY_TWO_FA = gql`
  mutation verifyTwoFA($data: Verify2FAInput) {
    verifyTwoFA(data: $data) {
      isVerified
      message
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation userLogout {
    userLogout {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation newAccessToken($refreshToken: String) {
    newAccessToken(refreshToken: $refreshToken) {
      user {
        id
        firstName
        lastName
        email
        profile
        isTwoFaEnabled
      }
      token
    }
  }
`;
