import { Modal } from 'antd';
import React from 'react';

export default function DeleteKeyModal({
  visible,
  setVisible,
  setTwoFaModalVisible,
}) {
  return (
    <Modal
      open={visible}
      centered
      title="Delete Key"
      onOk={() => {
        setVisible(false);
        setTwoFaModalVisible(true);
      }}
      onCancel={() => setVisible(false)}
      okText="Confirm"
    >
      Are you sure you want to permanently delete this key from all the
      environments? This action cannot be undone, and any services dependent on
      this key may be affected.
    </Modal>
  );
}
