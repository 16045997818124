import { Modal } from 'antd';
import React from 'react';

export default function DeleteEnvModal({
  visible,
  setVisible,
  setTwoFAModalOpen,
  setActionKey,
}) {
  return (
    <Modal
      open={visible}
      centered
      title="Delete Env"
      onOk={() => {
        setVisible(false);
        setActionKey('DELETE_ENV');
        setTwoFAModalOpen(true);
      }}
      onCancel={() => setVisible(false)}
      okText="Confirm"
    >
      Are you sure you want to permanently delete this environment? This action
      cannot be undone, and all associated key will be lost.
    </Modal>
  );
}
