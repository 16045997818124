import { Button, Card, Form, Input, Spin, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useMutation } from '@apollo/client';
import MobileLogoComponent from '../../components/MobileLogoComponent';
import useRouter from '../../hooks/useRouter';
import { VERIFY_TWO_FA } from './graphql/Mutations';
import { ROUTES } from '../../common/constants';
import { AppContext } from '../../AppContext';

function Verification() {
  const { dispatch, isTwoFaSet, fetchTwoFaKey } = useContext(AppContext);
  const {
    location: { state },
    navigate,
  } = useRouter();
  const isVerified = isTwoFaSet();
  const twoFaKey = fetchTwoFaKey();

  const [verifyTwoFA, { loading: verifyLoading }] = useMutation(VERIFY_TWO_FA, {
    onError: () => {},
    onCompleted: () => {
      dispatch({
        type: 'SET_TWO_FA_VERIFIED',
        data: true,
      });
      navigate(ROUTES?.MAIN);
    },
  });

  const onFinish = (values) => {
    verifyTwoFA({
      variables: {
        data: {
          code: values?.confirmationCode,
        },
      },
    });
  };

  useEffect(() => {
    if (isVerified && JSON.parse(isVerified)) {
      navigate(ROUTES?.MAIN, { replace: true });
    } else {
      navigate(ROUTES?.VERIFY, { state: twoFaKey });
    }
  }, []);

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={verifyLoading} wrapperClassName="full-width">
            <div id="logo" className="header-wrapper text-center">
              <MobileLogoComponent className="mr-8" />
              <div className="mt-8">
                <h3>LW Vault</h3>
              </div>
            </div>
            <div>
              <div className="d-flex flex-vertical align-center gap-16">
                <h3>Set Two-factor Authentication</h3>
                {state?.otpauthUrl && (
                  <QRCode value={state?.otpauthUrl} size={150} />
                )}
                <Typography.Text>
                  Scan the QR code using any authentication application on your
                  phone (e.g., Google Authenticator, Authy, Keeper, etc.) or
                  enter the following code:
                </Typography.Text>
                <Typography.Paragraph copyable className="auth-code">
                  {state?.otpauthUrl?.split('=')?.[1]}
                </Typography.Paragraph>
                <Form
                  layout="vertical"
                  className="confirmation-form"
                  onFinish={onFinish}
                >
                  <Form.Item
                    label="Enter the 6 figure confirmation code shown on the app:"
                    name="confirmationCode"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the confirmation code',
                      },
                    ]}
                  >
                    <Input.OTP size="large" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                      Verify
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Logicwind © {new Date()?.getFullYear()}
        </div>
      </div>
    </div>
  );
}

export default Verification;
